<template>
  <div>
    <van-cell-group>
      <van-cell
        :title="$t('What is BeeRicher')"
        is-link
        @click="toPage('what-is-beericher')"
      />
      <van-cell
        :title="$t('How to order?')"
        is-link
        @click="toPage('how-to-order')"
      />
      <van-cell
        :title="$t('How to pay?')"
        is-link
        @click="toPage('how-to-pay')"
      />
      <van-cell
        :title="$t('hc-Shipping')"
        is-link
        @click="toPage('shipping-policy')"
      />
      <van-cell
        :title="$t('How to handle short shipment and wrong shipment?')"
        is-link
        @click="toPage('short-wrong-shipment')"
      />
      <van-cell
        :title="
          $t('How to contact the cross-border logistic service provider?')
        "
        is-link
        @click="toPage('contact-service')"
      />
      <van-cell
        :title="$t('How to make a claim for damaged or lost shipment?')"
        is-link
        @click="toPage('damage-lost-shipment')"
      />
      <van-cell
        :title="$t('Cancel, Refund & Return Policy')"
        is-link
        @click="toPage('cancel-refund-return')"
      />
      <van-cell
        :title="
          $t('Five differences between BeeRicher and other sourcing agents')
        "
        is-link
        @click="toPage('five-difference')"
      />
    </van-cell-group>
  </div>
</template>

<script>
import { Cell, CellGroup } from "vant";
import { openWebPageLocale } from "@/utils/pages";

export default {
  components: {
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
  },
  data() {
    return {};
  },
  methods: {
    toPage(page) {
      openWebPageLocale("helpcenter", page);
    },
  },
};
</script>
<style lang="less" scoped>
</style>